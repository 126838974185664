<template>
    <div class="newsDeatil_box">
        <Header ref="Header"></Header>
        <div class="container">
            <div class="newsDetail_container clearfix">
                <!-- 新闻内容主体 -->
                <div class="news_detail_content f_left">
                    <!-- <h1 class="news_title" v-html="mainComment.title"></h1> -->
                    <!-- <p class="news_data">
                        <span class="news_time">{{mainComment.time}}</span>
                        <span class="news_views">阅读数：{{mainComment.hits}}</span>
                    </p> -->
                    <!-- 新闻内容 -->
                    <div class="news_content">
                        <!-- <m-div-panel :url.sync="mainComment.outUrl"></m-html-panel> -->
                        <div v-html="mainComment.outUrl" v-if="mainComment.coverType!=='VIDEO'"></div>
                        <!-- <video v-else :src="mainComment.coverImage" alt=""/> -->
                        <div v-else >
                        <div  class="news_title">{{mainComment.title}}</div>
                        <video :src="mainComment.coverImage|$DateFormatimg" class="video_box" controls autoplay></video>
                        </div>
                    </div>
                    <!-- 对新闻的分享、点赞、收藏 -->
                    <div class="news_operate clearfix">
                        <div class="read_num f_left">阅读数：{{mainComment.hits}}</div>
                        <div class="share_to_wx f_left">
                            <!-- 分享到：<i :class="['share_icon',{'act':isshowShare}]" @click="showShare" @mouseleave="hideShare"></i> -->
                            <!-- 分享code -->
                            <div class="code_share" v-show="isshowShare">
                                <vue-qr :text="shareUrl" :size="111" :margin="0" :dotScale = '1' class="shareImg"></vue-qr>
                                <span class="txt">使用微信扫一扫</span>     
                            </div>
                        </div>
                        <!-- <div class="like_collect f_right">
                            <span class="like" @click="getNewsLike">
                                <i :class="['like_icon', {'active': mainComment.isLike}]"></i>
                                <span class="like_num">{{mainComment.likes}}</span>
                            </span>
                            <span class="collect" @click="getNewsCollect">
                                <i :class="['collect_icon', {'active': isCollect}]"></i>
                                <span class="collect_text">收藏</span>
                            </span>
                        </div> -->
                    </div>
                    <!-- 对新闻的评论输入框 -->
                    <!-- <div class="comment_news">
                        <span class="head_portrait" :style="{backgroundImage: 'url(' + userAvatar + ')'}"></span>
                        <input type="text" class="comment_input" placeholder="说两句吧..." @focus="openDownload">
                        <button class="comment_btn" @click="openDownload">发布</button>
                    </div> -->
                    <!-- 全部评论 -->
                    <!-- <div class="all_comment_list" id="newsComment">
                        <div class="comment_title">
                            <i class="title_icon"></i>
                            <span class="title_text">全部评论({{commentData.totalComment}})</span>
                        </div>
                        <ul class="comment_ul_list">
                            <li class="comment_li" v-for="(item, index) in commentData.commentList" :key="index">
                                <span v-if="item.account" class="user_header" :style="{backgroundImage: 'url(' + item.account.imageSrc + ')'}"></span>
                                <span v-else class="user_header" style="background-image: url(http://www.hpaopao.com/paopao/default_photo.png)"></span>
                                主评论内容
                                <div class="comment_detail">
                                    <span class="user_name">{{item.account && item.account.nick}}</span>
                                    <span class="release_time">
                                        <span class="location" v-if="item.location != null">{{item.location}}</span>
                                        <span class="time">{{formatTime(item.createDate)}}</span>
                                    </span>
                                    <p class="comment_content" v-html="item.content"></p>
                                    <ul class="reply_operate clearfix">
                                        <li class="operate_li f_right" @click="replyComment(1, item, index)">
                                            <i class="operate_icon reply_icon"></i>
                                            <span class="operate_text">回复</span>
                                        </li>
                                         <li class="operate_li f_right" @click="giveReplyLike(1, item)">
                                            <i :class="['operate_icon like_icon', {'active': item.giveLike}]"></i>
                                            <span class="operate_text">{{item.likes}}</span>
                                        </li>
                                    </ul>
                                    <div class="reply_input_box clearfix" v-show="replyIndex == index">
                                        <input type="text" class="reply_input f_left" placeholder="说两句吧..." v-model="replyParams.content">
                                        <button class="reply_button f_left" @click="sendReply(item)">发布</button>
                                    </div>
                                    回复内容
                                    <div class="comment_reply_box" v-if="item.replyList.length > 0">
                                        <ul class="comment_reply_container">
                                            <li class="comment_reply_li" v-for="(subItem, subIndex) in item.replyList" :key="`${index}_${subIndex}`">
                                                <p class="comment_reply_content">
                                                    <span class="reply_nickname">{{subItem.repylNick}}</span>
                                                    <span class="reply_other_text">回复</span>
                                                    <span class="reply_nickname">{{subItem.followNick}}：</span>
                                                    {{subItem.content}}
                                                </p>
                                                <p class="comment_reply_operate clearfix">
                                                    <span class="time f_left">{{formatTime(subItem.createDate)}}</span>
                                                    <ul class="operate_ul f_right">
                                                        <li class="operate_ul_li f_left" @click="giveReplyLike(2, subItem)">
                                                            <i :class="['icon like', {'active': subItem.giveLike}]"></i>
                                                            <span class="text">{{subItem.likes}}</span>
                                                        </li>
                                                        <li class="operate_ul_li f_left" @click="replyComment(2, subItem, `${index}_${subIndex}`)">
                                                            <i class="icon reply"></i>
                                                            <span class="text">回复</span>
                                                        </li>
                                                    </ul>
                                                </p>
                                                <div class="reply_input_box clearfix" v-show="replyIndex == `${index}_${subIndex}`">
                                                    <input type="text" class="reply_input f_left" placeholder="说两句吧..." v-model="replyParams.content">
                                                    <button class="reply_button f_left" @click="sendReply(item)">发布</button>
                                                </div>
                                            </li>
                                        </ul>
                                        <div class="see_all_reply" v-show="item.isShowAll == true" @click="seeAllReply(item)">
                                            <p class="see_all_text">查看全部</p>
                                            <i class="see_all_icon"></i>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        
                    </div> -->
                    <!-- <div class="page_container" v-show="isShowPagination">
                        <m-pagination :currentpage="currentPage" :total="totalPage" @current-change="currentChange"></m-pagination>
                    </div> -->
                </div>
                <!-- 推荐新闻 -->
                <div class="f_left">
                    <m_recommend @changeNewsId="changeNewsId"></m_recommend>
                </div>
            </div>
        </div>
        <!-- 弹框下载app -->
        <Download
          :isDownloadshow="isDownloadshow"
          :url="downloadUrl"
          @closeAlert="closeAlert"
        ></Download>
        <Footer :footerBgColor="footerBgColor"></Footer>
        <div class="reply_success_tips" v-show="isReplySuccess" id="tipsBox">
            <i class="success_icon"></i>
            <span class="success_text">{{tipsText}}</span>
        </div>
    </div>
</template>
<script>
import { newsInterface } from '@/config/request'
import linkedmeLink from '../../../config/linkdeme';
import m_recommend from '../recommend/recommend'
import { attrCenter, changeTime, downloadApp } from '@/config/utils'
import vueQr from 'vue-qr'
export default {
    name: 'newsDetail',
    components: {
        m_recommend,
        vueQr
    },
    watch: {
        '$route'() {
            console.log(this.$route.name)
            if(this.$route.name === 'newsDetail' ){
                this.getData()
                // this.getCommentData(null)
                this.recordData(4)
            }
        }
    },
    data(){
        return {
            isLogin: null, // 是否登录
            userAvatar: '', // 用户头像
            footerBgColor: '#FBFBFB', // 共用底部背景色
            currentPage: 1, // 当前页
            totalPage: 0, // 总条数
            isShowPagination: true, // 是否显示分页
            articleId: '', // 文章id
            mobile: '', // 手机号
            accountId: '', // 用户id
            access_token: '', // token
            downloadUrl:'',//二维码地址
            isDownloadshow: false,
            mainComment: { // 新闻主体数据
                title: '', // 标题
                time: '', // 时间
                hits: '', // 阅读数
                outUrl: '', // 文章链接
                isLike: '', // 是否点赞
                likes: '', // 点赞数
            },
            isCollect: '', // 是否收藏
            commentData: {
                totalComment: '', // 全部评论数
                commentList: [], // 评论列表
            },
            replyIndex: null, // 是否显示回复输入框
            isReplySuccess: false, // 是否回复成功
            tipsText: '', // 回复完成的提示文案
            replyParams: { // 回复评论的参数
                mainCommentId: '',   // 主评id
                commentId: '',       // 评论id
                replyAuthor: '',     // 回复人id
                followAccountId: '', // 被评论人id
                content:'',        // 内容
            },
            // allReplyItem: null, // 查看全部的数据信息
            shareUrl:'',//分享二维码地址
            isshowShare:false,//显示分享弹框
            
        }
    },
    methods: {
        // 显示登录弹框
        showLoginBox() {
            this.$refs.Header.Login()
        },
        // 推荐新闻查看详情
        changeNewsId(id) {
            this.articleId = id
        },
        // 切换分页
        currentChange(page) {
            this.currentPage = page
            // this.getCommentData(null)
        },
        // 获取数据
        getData() {
            newsInterface.newsDetail(this.articleId).then(res => {
                console.log(res);
                if(res.code == 200) {
                    res=res.data
                    res.richText=this.$showHtml(res.richText)
                    console.log(res.richText)
                    this.mainComment = { // 新闻主体数据
                        title: res.title,
                        time: changeTime(res.publishTime),
                        hits: res.hits,
                        outUrl: res.richText,
                        isLike: res.praise,
                        likes: res.totalPraise,
                        coverType:res.coverType,
                        coverImage:res.coverImage
                    }
                    document.getElementById('article_tit').innerHTML = res.title+'-马拉松报名';
                    // document.getElementById('article_key').content+=`,${res.objectData.name}`;
                }
            })
        },
        // 统计数据
        recordData(type) {
            newsInterface.recordingData({
                id: this.articleId,
                type: type,
                // accountId: this.accountId
            }).then(res => {
                if(type == 1) {
                    this.mainComment.isLike = !this.mainComment.isLike
                    this.mainComment.likes = this.mainComment.isLike ? this.mainComment.likes += 1 : this.mainComment.likes < 0 ? 0 : this.mainComment.likes -= 1
                }
            })
        },
        // 获取评论数据
        getCommentData(isClickShowAll) {
            newsInterface.newsCommentList({
                articelId: this.articleId,
                accountId: this.accountId,
                pageNo: this.currentPage,
                pageSize: 10
            }).then(res => {
                if(res.code == 1) {
                    let commentList = []
                    this.commentData.totalComment = res.objectData.commentTotal // 全部评论数
                    this.totalPage = res.objectData.commentTotal
                    this.isCollect = res.objectData.isCollection
                    this.isShowPagination = res.objectData.commentTotal == 0 ? false : true
                    res.list.forEach((item, index) => {
                        item.giveLike = item.likeId ? true : false
                        item.replys.length > 0 && item.replys.forEach(subItem => {
                            subItem.giveLike = subItem.likeId ? true : false
                            subItem.likes = subItem.likes ? subItem.likes : 0
                        })
                        item.showAllIndex = index
                        if(isClickShowAll != item.showAllIndex) {
                            item.isShowAll = item.replys.length > 4 ?  true : false
                            item.replyList = item.replys.length > 4 ? item.replys.slice(0, 4) : item.replys
                        } else {
                            item.isShowAll = false
                            item.replyList = item.replys
                        }
                    })
                    this.commentData.commentList = res.list // 评论列表
                    
                }
            })
        },
        // 查看全部评论
        seeAllReply(item) {
            // console.log(item)
            item.replyList = item.replys
            item.isShowAll = false
        },
        // 格式化时间
        formatTime(time) {
            return changeTime(time)
        },
        // 给新闻点赞
        getNewsLike() {
            if(this.isLogin) { // 已登录
                this.recordData(1)
            } else { // 未登录
                // 弹出登录框
                this.showLoginBox()
            }
        },
        // 收藏新闻
        getNewsCollect() {
            if(this.isLogin) {
                this.recordData(2)
                // 如果为已收藏，则点击为取消收藏；如果未收藏，则点击为收藏
                let status = this.isCollect ? 0 : 1
                newsInterface.getNewsCollect({
                    mobile: this.mobile,
                    articleId: this.articleId,
                    status: status
                }).then(res => {
                    if(res.code == 1) {
                        this.isCollect = !this.isCollect
                    }
                })
            } else {
                this.showLoginBox()
            }
            
        },
        // 给评论点赞
        giveReplyLike(type, item) {
            if(this.isLogin) {
                // type  1 给主评点赞  2 给回复点赞
                let params = {
                    mainCommentId: type == 1 ? item.id : item.commentId, // 主评id
                    commentId: item.id, // 评论id
                    accountId: type == 1 ? item.accountId : item.replyAuthor, // 被点赞用户id
                    authorId: this.accountId // 点赞用户id
                }
                let isLike
                newsInterface.getReplyLike(params).then(res => {
                    let isCancelLike = res.msg.indexOf('取消')
                    if(isCancelLike != -1) { // 取消点赞
                        item.giveLike = false
                        isLike = false
                    } else { // 点赞
                        item.giveLike = true
                        isLike = true
                    }
                    if(isLike) {
                        item.likes += 1
                    } else {
                        item.likes = item.likes < 0 ? 0 : item.likes - 1
                    }
                })
            } else {
                this.showLoginBox()
            }
            
        },
        // 回复
        replyComment(type, item, index) {
            if(this.isLogin) {
                // 展示输入框
                let oldIndex = this.replyIndex
                if(index == oldIndex) {
                    this.replyIndex = null
                } else {
                    this.replyIndex = index
                }
                // 回复评论的参数
                this.replyParams = {
                    mainCommentId: type == 1 ? item.id : item.commentId,   // 主评id
                    commentId: item.id,       // 评论id
                    replyAuthor: this.accountId,     // 回复人id
                    followAccountId: type == 1 ? item.accountId : item.replyAuthor, // 被评论人id
                    content:'',        // 内容
                }
            } else {
                this.showLoginBox()
            }
            
        },
        // 发送回复内容
        sendReply(item) {
            if(!this.replyParams.content) {
                this.showReplyTips('您没有输入任何内容')
            } else {
                newsInterface.newsReply(this.replyParams).then(res => {
                    this.showReplyTips('回复成功')
                    this.replyParams.content = ''
                    this.replyIndex = null
                    item.isShowAll ? this.getCommentData(null) : this.getCommentData(item.showAllIndex)
                    
                })
            }
        },
        // 显示文案提示
        showReplyTips(text) {
            this.tipsText = text
            this.isReplySuccess = true
            setTimeout(() => {
                this.isReplySuccess = false
            }, 1500);
        },
        // 点击发表评论提示下载app
        openDownload() {
            this.isDownloadshow = true;
            // 进入赛事详情点评
            linkedmeLink({
                id: this.articleId,
                path: 'run/newsDetail', //跳转app的名字
            }).then(res => {
                // console.log(res)
                this.downloadUrl = res.url;
            })
        },
        // 关闭下载app弹框
        closeAlert(v) {
            this.isDownloadshow = v;
        },
        //点击分享出现二位码弹框
        showShare() {
            this.isshowShare = !this.isshowShare;
            let id = this.accountId ? this.accountId : (Math.random() * 100).toFixed()
            this.shareUrl = 'https://h5.marathonbm.com/#/newsDetail?newsId='+ this.articleId + '&accountId=' + id + '&mobile=' + this.mobile;
        },
        hideShare() {
            this.isshowShare = false;
        },
    },
    created() {
        let userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
        this.access_token = sessionStorage.getItem('access_token')
        this.isLogin = this.access_token ? true : false
        this.accountId = userInfo ? userInfo.id : ''
        this.mobile = userInfo ? userInfo.mobile : ''
        this.userAvatar = userInfo ? userInfo.imageSrc : 'http://www.hpaopao.com/paopao/default_photo.png'
        this.articleId = this.$route.query.articleId
        this.getData()
        // this.getCommentData(null)
        // this.recordData(4)
    },
}
</script>

<style>
@import './style/newsDetail.css';
</style>
